var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "backend-factoring-autocomplete" },
    [
      _c("v-text-field", {
        attrs: { label: "Factoring Company", loading: _vm.loading },
        model: {
          value: _vm.search,
          callback: function ($$v) {
            _vm.search = $$v
          },
          expression: "search",
        },
      }),
      _vm.factoringCompanies.length
        ? _c(
            "div",
            { staticClass: "backend-factoring-autocomplete__popover" },
            _vm._l(_vm.factoringCompanies, function (factoringCompany) {
              return _c(
                "div",
                {
                  key: factoringCompany.id,
                  staticClass: "backend-factoring-autocomplete__popover__item",
                  on: {
                    click: function ($event) {
                      return _vm.selectFactoringCompany(factoringCompany)
                    },
                  },
                },
                [
                  _c("span", [_vm._v(_vm._s(factoringCompany.businessName))]),
                  _c("span", [_vm._v(_vm._s(factoringCompany.addressLine1))]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }